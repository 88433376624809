import React from "react"
import styles from "./discount.module.scss"
import { openOverlay } from "../../../../utils/overlay"

const FreeSummer = ({ topInd, className }) => (
  <div className={`${styles.discount} ${className}`} style={ topInd ? {marginTop: topInd + 'px' } : {}}>
    <div className={`container ${styles.discountContainer}`}>
      <div className={styles.wrapper}>
        <div className={styles.free}>СКИДКА НОВЫМ КЛИЕНТАМ</div>
        <div className={styles.plus}>+ подарочные дни</div>
        {/* <div className={styles.free}>БОНУСЫ НОВЫМ КЛИЕНТАМ</div>
        <div className={styles.plus}>Цены улетают в отпуск!</div> */}

        <div className={styles.text}>
          <p><b>1 филиал</b></p>
          <p><span>до 25%</span> на подписку от 3-х месяцев*</p>
          <p><b>от 2-х филиалов</b></p>
          <p><span>до 10%</span> на любую подписку</p>
          {/* <p><b>При покупке подписки на:</b></p>
          <div className={styles.pstr}><nobr>3 месяца:</nobr>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <div className={styles.discwrapper}>
              <div className={styles.discwhite}>скидка 1 100 ₽</div>
            </div>
          </div>
          <div className={styles.pstr}><nobr>6 месяцев:</nobr>&nbsp;&nbsp;&nbsp;
            <div className={styles.discwrapper}>
              <div className={styles.discwhite}>скидка 2 300 ₽</div>
              <div className={styles.greenstr}>+ 7 подарочных дней&nbsp;</div>
            </div>
          </div>
          <div className={styles.pstr}><nobr>12 месяцев:</nobr>&nbsp;
            <div className={styles.discwrapper}>
              <div className={styles.discwhite}>скидка 3 400 ₽</div>
              <div className={styles.greenstr}>+ 20 подарочных дней</div>
            </div>
          </div>
        </div> */}
        {/* <button className={`${styles.cta}`}
                onClick={() => openOverlay('free-summer', "action", "trial")}>
          Зарегистрироваться
        </button> */}
        {/* <div className={styles.text}>
          
        </div> */}
        <button className={`${styles.cta}`} onClick={() => openOverlay('free-summer', "action", "trial")}>
          Зарегистрироваться
        </button>
        <div className={styles.option}>* Скидка распространяется при единовременной покупке по тарифам "Опти" и "Макси" на 3, 6 или 12 месяцев.</div>
        {/* <div className={styles.option}>Срок проведения акции ограничен.</div> */}
      </div>
    </div>
  </div>
  </div>
)

export default FreeSummer
