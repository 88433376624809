const FEATURES_DATA_RU = [
  {
    name: "portal",
    color: "#6D20D2",
    title: "Кабинет клиента",
    description: "Персонализируйте общение с клиентами",
    image: require("../../../../images/features/feature_messages.png"),
  },
  {
    name: "notifications",
    color: "#319DED",
    title: "Автоуведомления",
    description: "Информируйте клиентов и сотрудников",
    image: require("../../../../images/features/feature_information.png"),
  },
  {
    name: "tasks",
    color: "#E75D49",
    title: "Планировщик задач",
    description: "Ставьте задачи сотрудникам через планировщик",
    image: require("../../../../images/features/feature_tasks.png"),
  },
  {
    name: "mobile",
    color: "#6D20D2",
    title: "Мобильная версия",
    description: "Работайте с телефона",
    image: require("../../../../images/features/mobile.png"),
  },
]

const FEATURES_DATA_EN = [
  {
    name: "portal",
    color: "#6D20D2",
    title: "Client Cabinet",
    description: "Personalize customer communications",
    image: require("../../../../images/features/feature_messages.png"),
  },
  {
    name: "notifications",
    color: "#319DED",
    title: "Auto notifications",
    description: "Inform customers and employees",
    image: require("../../../../images/features/feature_information.png"),
  },
  {
    name: "tasks",
    color: "#E75D49",
    title: "Tasks for employees",
    description: "Schedule tasks for employees",
    image: require("../../../../images/features/feature_tasks.png"),
  },
  {
    name: "mobile",
    color: "#6D20D2",
    title: "mobile version",
    description: "Work from your phone",
    image: require("../../../../images/features/mobile.png"),
  },
]

const FEATURES_DATA_DE = [
  {
    name: "portal",
    color: "#6D20D2",
    title: "Kundenkabinett",
    description: "Kundenkommunikation personalisieren",
    image: require("../../../../images/features/feature_messages.png"),
  },
  {
    name: "notifications",
    color: "#319DED",
    title: "Automatische Benachrichtigungen",
    description: "Kunden und Mitarbeiter informieren",
    image: require("../../../../images/features/feature_information.png"),
  },
  {
    name: "tasks",
    color: "#E75D49",
    title: "Task Manager",
    description: "Task Manager",
    image: require("../../../../images/features/feature_tasks.png"),
  },
  {
    name: "mobile",
    color: "#6D20D2",
    title: "mobile Version",
    description: "Arbeiten Sie von Ihrem Telefon aus",
    image: require("../../../../images/features/mobile.png"),
  },
]

export const getFeaturesList3 = (current, lang) => {
  const FEATURES_DATA = getFeaturesData(lang)
  const featureList = current
    ? [
      ...FEATURES_DATA.filter(feature => feature.name !== current),
      FEATURES_DATA.find(feature => feature.name === current),
    ]
    : FEATURES_DATA
  return featureList.slice(0, 8)
}

export const getFeaturesData = (lang) => {
  switch (lang) {
    case 'en':
      return FEATURES_DATA_EN
    case 'de':
      return FEATURES_DATA_DE
    default:
      return FEATURES_DATA_RU
  }
}
