const FEATURES_DATA_RU = [
  {
    name: "messengers",
    color: "#F2A819",
    title: "Мессенджеры",
    description: "Общайтесь в мессенджерах<br/> через систему",
    image: require("../../../../images/features/feature_messangers.png"),
  },
  {
    name: "widgets",
    color: "#03C4A4",
    title: "Виджеты",
    description: "Автоматизируйте <br/> сбор лидов",
    image: require("../../../../images/features/feature_automatization.png"),
  },
  {
    name: "onlinecashbox",
    color: "#E75D49",
    title: "Онлайн-касса",
    description: "Отправляйте чеки онлайн",
    image: require("../../../../images/features/onlinecashbox.png"),
  },
  {
    name: "iptelephony",
    color: "#319DED",
    title: "IP-телефония",
    description: "Контролируйте звонки",
    image: require("../../../../images/features/iptelephony.png"),
  },
]

const FEATURES_DATA_EN = [
  {
    name: "messengers",
    color: "#F2A819",
    title: "Messengers",
    description: "Communicate in messengers through the system",
    image: require("../../../../images/features/feature_messangers.png"),
  },
  {
    name: "widgets",
    color: "#03C4A4",
    title: "Widgets",
    description: "Automate your lead collection", 
    image: require("../../../../images/features/feature_automatization.png"),
  },
  {
    name: "onlinecashbox",
    color: "#E75D49",
    title: "Online cash desk",
    description: "Send checks online",
    image: require("../../../../images/features/onlinecashbox.png"),
  },
  {
    name: "iptelephony",
    color: "#319DED",
    title: "IP telephony",
    description: "Control calls",
    image: require("../../../../images/features/feature_abonements.png"),
  },
]

const FEATURES_DATA_DE = [
  {
    name: "messengers",
    color: "#F2A819",
    title: "Boten",
    description: "Kommunizieren Sie in Messengern über das System",
    image: require("../../../../images/features/feature_messangers.png"),
  },
  {
    name: "widgets",
    color: "#03C4A4",
    title: "Widgets",
    description: "Automatisieren Sie Ihre Lead-Sammlung", 
    image: require("../../../../images/features/feature_automatization.png"),
  },
  {
    name: "onlinecashbox",
    color: "#E75D49",
    title: "Online-Kasse",
    description: "Schecks online versenden",
    image: require("../../../../images/features/onlinecashbox.png"),
  },
  {
    name: "iptelephony",
    color: "#319DED",
    title: "IP-Telefonie",
    description: "Anrufe kontrollieren",
    image: require("../../../../images/features/feature_abonements.png"),
  },
]

export const getFeaturesList2 = (current, lang) => {
  const FEATURES_DATA = getFeaturesData(lang)
  const featureList = current
    ? [
      ...FEATURES_DATA.filter(feature => feature.name !== current),
      FEATURES_DATA.find(feature => feature.name === current),
    ]
    : FEATURES_DATA
  return featureList.slice(0, 8)
}

export const getFeaturesData = (lang) => {
  switch (lang) {
    case 'en':
      return FEATURES_DATA_EN
    case 'de':
      return FEATURES_DATA_DE
    default:
      return FEATURES_DATA_RU
  }
}
