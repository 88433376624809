import React from "react"
import SEO from "../../components/seo"
import HeroFeaturePage from "../../components/pages/shared/hero/feature-index-page"
import FeaturesComponent from "../../components/pages/main/features-index"
import ContactFormComponent from "../../components/pages/shared/contact-form"
import Layout from "../../components/layout"
import Discount from "../../components/pages/main/discount"
import Breadcrumb from '../../components/controls/breadcrumbs';

const FeatureIndexPage = (props) => {
  const crumbs = [
    { name: 'Главная', url: '/'},
    { name: 'Возможности', url: ''},
  ];
  return (
    <Layout headerColor={"#03C4A4"} page={"feature-index"}>
      <SEO title="Познакомьтесь с возможностями CRM-системы Параплан"
           description="Оцените возможности CRM-системы Параплан. Управление клиентской базой, сбор заявок, контроль сотрудников, интеграции и многое другое."
           url={"https://paraplancrm.ru" + props.location.pathname} />
      <HeroFeaturePage
        title='Возможности Параплан CRM'
        description='Используйте максимум для достижения высоких результатов'
        color='#03C4A4'
      />
      <Breadcrumb crumbs={ crumbs } arrowcolor={"green"}/>
      <FeaturesComponent title='Учет клиентов, лидов, сотрудников' subtitle="Увеличьте выручку компании с помощью CRM-системы" num={"1"}/>
      <Discount />
      <FeaturesComponent title='Интеграции с полезными сервисами' subtitle="Ускорьте работу бизнеса благодаря автоматизации процессов" num={"2"}/>
      <FeaturesComponent title='Инструменты для коммуникации и планирования' subtitle="Развивайте клиентский сервис и стимулируйте повторные покупки" num={"3"}/>
      <ContactFormComponent />
    </Layout>
  )
}

export default FeatureIndexPage
