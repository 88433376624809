import React from "react"

import styles from "./features-index.module.scss"
import FeaturesCard from "./features_card"

import { LangContext } from "../../../lang-context"
import { getFeaturesList1 } from "./features_data1"
import { getFeaturesList2 } from "./features_data2"
import { getFeaturesList3 } from "./features_data3"
function getData(num, current, isFeatureList, styles) {
  switch(num) {
    case '1':
    return (
      <LangContext.Consumer>
        {lang => getFeaturesList1(current, lang).map(feature =>
          <FeaturesCard key={feature.name}
                        className={feature.name === current ? styles.current : ""}
                        isFeatureList={isFeatureList}
                        {...feature}
          />)}
      </LangContext.Consumer>
    );
    case '2':
      return (
        <LangContext.Consumer>
          {lang => getFeaturesList2(current, lang).map(feature =>
            <FeaturesCard key={feature.name}
                          className={feature.name === current ? styles.current : ""}
                          isFeatureList={isFeatureList}
                          {...feature}
            />)}
        </LangContext.Consumer>
      );
    case '3':
      return (
        <LangContext.Consumer>
          {lang => getFeaturesList3(current, lang).map(feature =>
            <FeaturesCard key={feature.name}
                          className={feature.name === current ? styles.current : ""}
                          isFeatureList={isFeatureList}
                          {...feature}
            />)}
        </LangContext.Consumer>
      );
  }
}

const FeaturesComponent = ({ current, title, isFeatureList, subtitle, num }) => (
  <div id='features'
       className={`container ${styles.features}`}>
    <h2 className={styles.title}>{isFeatureList ? "Дополнительные возможности" : title}</h2>
    <p className={styles.subtitle}>{subtitle}</p>
    <div className={styles.grid}>
      {getData(num, current, isFeatureList, styles)}
    </div>
  </div>
)

export default FeaturesComponent
